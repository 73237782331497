(function () {
  'use strict';

  class DiscussionsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    discussions() {
      return this.$resource(`${this.env.api}/discussions/:id`, {id: '@id'}, {update: {method: 'PUT'}});
    }

    latest() {
      return this.$resource(`${this.env.api}/discussions/latest/classroom/:classroom_id`, {classroom_id: '@classroom_id'});
    }

    activeDiscussions() {
      return this.$resource(`${this.env.api}/discussions/active-discussions/:group/:groupid`, {groupid: '@groupid', group: '@group'});
    }

    discussion() {
      return this.$resource(`${this.env.api}/discussions/:id/:classroom/:classroom_id`, {id: '@id', classroom: '@classroom', classroom_id: '@classroom_id'}, {update: {method: 'PUT'}});
    }

    entriesModule() {
      return this.$resource(`${this.env.api}/discussions/entries-by-group/:group_id`, {group_id: '@group_id'}, {update: {method: 'PUT'}});
    }

    entries() {
      return this.$resource(`${this.env.api}/discussions/:id/discussion_entries`, {id: '@discussion_entry.id'});
    }

    entriesAll() {
      return this.$resource(`${this.env.api}/discussions/:id/classroom/:classroom_id/entries`, {id: '@id', classroom_id: '@classroom_id'});
    }

    group() {
      return this.$resource(`${this.env.api}/discussions/:id/entries`, {id: '@id'});
    }

    entry() {
      return this.$resource(`${this.env.api}/discussions/:id/entries/:entry_id`, {id: '@id', entry_id: '@entryId'}, {update: {method: 'PUT'}});
    }

  }

  /**
   * @ngdoc service
   * @name components.service:DiscussionsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('DiscussionsRest', DiscussionsRest);
}());
